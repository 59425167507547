<script>
import FormInput from '../FormInput'
import { Button, message } from 'ant-design-vue'
export default {
  data() {
    return {
      buttonText: '获取验证码',
      timeState: 0,
      isCountingDown: false,
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    startTime() {
      if (!this.isCountingDown) {
        this.isCountingDown = true
        let time = 60
        this.buttonText = `${time}秒后重新获取`
        let timer = setInterval(() => {
          time--
          this.buttonText = `${time}秒后重新获取`
          if (time === 0) {
            clearInterval(timer)
            this.buttonText = '获取验证码'
            this.isCountingDown = false
          }
        }, 1000)
      }
    },
    onClick() {
      if (!this.isCountingDown) {
        if (this.item.onClick) {
          this.item.onClick().then(() => {
            message.success('短信验证码发送成功')
            this.startTime()
          })
        }
      }
    },
  },
  render() {
    return (
      <div class="form-input-code">
        <FormInput item={this.item} value={this.value} onChange={(value) => this.$emit('change', value)} />
        <Button onClick={this.onClick}>{this.buttonText}</Button>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.form-input-code {
  display: flex;
  align-items: center;
  & > div {
    margin-top: 0px;
  }
  & > div:nth-child(1) {
    width: calc(100% - 130px);
    margin-right: 5px;
  }
  & > *:nth-child(2) {
    width: 130px;
  }
}
</style>
